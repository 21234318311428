import { takeEvery, put } from "redux-saga/effects";
import {
  GET_NEWS_DETAILS_DATA,
  GET_ENEVELOPE_DATA,
  GET_RELEASE_VERSION,
  GET_MORE_NEWS_DATA,
  GET_NEWS_NEWSLETTER_BANNER_DATA,
  GET_MEDIA_BANNER,
  GET_NEWS_DETAILS_RECOMMENDED,
  GET_NEWS_DETAILS_PAGE_DATA,
} from "./constants";
import { getBW, get, getBWWithNF } from "../../utils/api";
import {
  getNewsDetailsSuccess,
  getNewsDetailsFail,
  getReleaseVersionSuccess,
  getReleaseVersionFail,
  getEnevelopDataSuccess,
  getEnevelopDataFail,
  getMoreNewsDataSuccess,
  getMoreNewsDataFail,
  getNewsNewsLetterBannerDataSuccess,
  getNewsNewsLetterBannerDataFail,
  getMediaBannerSuccess,
  getMediaBannerFail,
  getNewsDetailsRecommendedSuccess,
  getNewsDetailsRecommendedFail,
  getNewsDetailsPageDataSuccess,
  getNewsDetailsPageDataFail,
} from "./actions";
import {
  formatMoreNews,
  formatNewsData,
  formatNewsDetail,
} from "../../utils/helper";
import { language } from "../../utils/constants";
import { updateLanguageData, updateFooterLanguageData } from "../app/actions";

function* getNewsDetail(newsId) {
    let data = yield getBWWithNF(`/news/release/${newsId.data}`)
    .then((result) => {
      return result;
    })
    .catch(() => console.error());
  if (data && data.status === 200) {
    const newsDetail = yield data.data.data;
    const updatedData = formatNewsDetail(newsDetail);
    yield put(getNewsDetailsSuccess(updatedData));
  } else {
    yield put(getNewsDetailsFail(data));
  }
}

function* getReleaseVersion(newsId) {
  let data = yield getBW(`/news/versions/${newsId.data}`)
    .then((result) => {
      return result;
    })
    .catch(() => console.error());
  if (data && data.status === 200) {
    const releaseVersion = yield data.data.data;
    let verName = [];
    if (releaseVersion) {
      releaseVersion.map((data) => {
        if (language[data.language.toLowerCase()]) {
          if (data.altids) {
            data.altids.map((data1) => {
              if (data1.role === "NewsItemId") {
                verName.push({
                  name: language[data.language.toLowerCase()],
                  id: data1.value,
                  code: data.language.toLowerCase(),
                });
              }
            });
          }
        }
      });
    }
    yield put(getReleaseVersionSuccess(verName));
  } else {
    yield put(getReleaseVersionFail(data));
  }
}

function* getEnevelopData(newsId) {
  let data = yield getBW(`/news/envelope/${newsId.data}`)
    .then((result) => {
      return result;
    })
    .catch(() => console.error());
  if (data && data.status === 200) {
    const envelopeData = yield data.data.data;
    yield put(getEnevelopDataSuccess(envelopeData));
  } else {
    yield put(getEnevelopDataFail(data));
  }
}

function* getMoreNews(newsId) {
  let data = yield getBW(`/news/related/${newsId.data}`)
    .then((result) => {
      return result;
    })
    .catch(() => console.error());
  if (data && data.status === 200) {
    const news = yield data.data.data;
    let moreNews = [];
    if (news) {
      news.map((data) => {
        let formatData = formatMoreNews(data);
        moreNews.push(formatData);
      });
    }
    yield put(getMoreNewsDataSuccess(moreNews));
  } else {
    yield put(getMoreNewsDataFail(data));
  }
}

function* getNewsLetterSignUpData() {
  let data = yield get("/bwcms/globalcomponent/newsletter")
    .then((result) => {
      return result;
    })
    .catch(() => console.error());
  if (data && data.status === 200) {
    const newsLetterSignUpData = yield data.data;
    yield put(getNewsNewsLetterBannerDataSuccess(newsLetterSignUpData));
  } else {
    yield put(getNewsNewsLetterBannerDataFail(data));
  }
}

function* getMediaBannerData() {
  let data = yield get("/bwcms/globalcomponent/media-banner")
    .then((result) => {
      return result;
    })
    .catch(() => console.error());
  if (data && data.status === 200) {
    const mediaBannerData = yield data.data;
    yield put(getMediaBannerSuccess(mediaBannerData));
  } else {
    yield put(getMediaBannerFail(data));
  }
}

function* getNewsDetailsRecommended(newsId) {
  let data = yield getBW(`/news/recommended/${newsId.data}`)
    .then((result) => {
      return result;
    })
    .catch(() => console.error());
  if (data && data.status === 200) {
    let recNews = yield typeof data.data.data === "string"
      ? JSON.parse(data.data.data)
      : data.data.data.data;
    let updatedData = recNews.map((data) => {
      return formatNewsData(data);
    });

    yield put(getNewsDetailsRecommendedSuccess(updatedData));
  } else {
    yield put(getNewsDetailsRecommendedFail(data));
  }
}

function* getNewsDetailsPageData(params) {
  let data = yield get("/bwcms/page/" + params.data)
    .then((result) => {
      return result;
    })
    .catch(() => console.error());
  if (data && data.status === 200) {
    const pageData = yield data.data;
    yield put(getNewsDetailsPageDataSuccess(pageData));
    yield put(updateLanguageData(pageData.languages));
    //temp code for service page footer links
    yield put(updateFooterLanguageData());
  } else {
    yield put(getNewsDetailsPageDataFail(data));
  }
}

function* newsDetailSaga() {
  yield takeEvery(GET_NEWS_DETAILS_DATA, getNewsDetail);
  yield takeEvery(GET_RELEASE_VERSION, getReleaseVersion);
  yield takeEvery(GET_ENEVELOPE_DATA, getEnevelopData);
  yield takeEvery(GET_MORE_NEWS_DATA, getMoreNews);
  yield takeEvery(GET_NEWS_NEWSLETTER_BANNER_DATA, getNewsLetterSignUpData);
  yield takeEvery(GET_MEDIA_BANNER, getMediaBannerData);
  yield takeEvery(GET_NEWS_DETAILS_RECOMMENDED, getNewsDetailsRecommended);
  yield takeEvery(GET_NEWS_DETAILS_PAGE_DATA, getNewsDetailsPageData);
}

export default newsDetailSaga;
