import React, { useState, memo } from "react";
import ShareIconImg from "../../images/news/share.svg";
import HoverShareIconImg from "../../images/news/share_hover.svg";
import ShareIconWrapper from "./shareicon";
import { Popover, Row, Col } from "antd";
import "./shareicon.scss";

const ShareIconComponent = (props) => {
  const [showPopOver, setShowPopOver] = useState(false);

  const onShowPopover = () => {
    if (!showPopOver) {
      setShowPopOver(true);
    }
  };

  const content = (
    <div>
      {showPopOver ? (
        <Row>
          <Col span={24}>
            {showPopOver ? (
              <div id={`share-buttons`} tabIndex="0">
                <div
                  tabIndex="0"
                  className="shareaholic-canvas"
                  data-app="share_buttons"
                  data-app-id="33103291"
                  data-link={
                    props.serviceShareLink ? props.serviceShareLink :
                    (window.location.origin +
                    "/newsdetail/" +
                    props.id +
                    "/" +
                    props.language)
                  }
                ></div>
              </div>
            ) : (
              <></>
            )}
          </Col>
        </Row>
      ) : (
        <></>
      )}
    </div>
  );

  const handleOpenChange = (evt) => {
    setShowPopOver(evt);
  };

  return (
    <>
      <ShareIconWrapper>
        <Popover
          content={content}
          trigger="click"
          open={showPopOver}
          onOpenChange={handleOpenChange}
          showArrow={false}
          zIndex={9999999}
        >
          <div className="shareIconSec" tabIndex="0" style={{ cursor: 'pointer' }}>
            <img
              width={props.width ? props.width : "50"}
              src={ShareIconImg}
              alt={"Share icon"}
              onMouseOver={(e) => (e.currentTarget.src = HoverShareIconImg)}
              onMouseOut={(e) => (e.currentTarget.src = ShareIconImg)}
              onClick={() => onShowPopover()}
            />
          </div>
        </Popover>
      </ShareIconWrapper>
    </>
  );
};

export default memo(ShareIconComponent);
