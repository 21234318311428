import styled from "styled-components";
import colors from "../../utils/colorsFunctional.json";
const PageHeaderComponentWrapper = styled.div`
  .header-menu {
    background-color: ${colors["@highlight-text"]};
  }
  .header-menu ul {
    background-color: ${colors["@highlight-text"]};
  }
  .bw-header .header-btn {
    color: ${colors["@success-dark-text"]};
  }
  .bw-header .header-btn:hover,
  .bw-header .header-btn1:hover {
    background: ${colors["@btn-primary-hover"]};
  }
  .bw-header .header-btn1,
  .bw-header .anticon-menu {
    color: ${colors["@success-dark-text"]};
  }

  .bw-header .header-search,
  .header-search-mob {
    border: 1px solid ${colors["@headings-light"]};
  }
  .bw-header .header-search:hover,
  .header-search-mob:hover {
    border: 1px solid ${colors["@success-dark-text"]};
  }
  .bw-header .header-select .ant-select-selector {
    color: ${colors["@success-dark-text"]};
  }

  .bw-header .header-select .ant-select-arrow,
  .header-search-mob .ant-select-arrow {
    color: ${colors["@success-dark-text"]};
  }
  .bw-header .header-search input,
  .header-search-mob input {
    background: ${colors["@highlight-text"]};
  }
  .bw-header .ant-menu-submenu-selected {
    color: ${colors["@nav-mobile-content"]};
    background-color: ${colors["@btn-primary-hover"]};
  }
  .bw-header .ant-menu-submenu:hover {
    color: ${colors["@nav-mobile-content"]};
    background-color: ${colors["@btn-primary-hover"]};
  }

  .ant-menu-submenu-popup ul a h3 {
    color: ${colors["@headings-light"]};
  }

  .bw-header .ant-menu-submenu-selected > .ant-menu-submenu-title a,
  .bw-header .ant-menu-submenu-title a {
    color: ${colors["@headings-light"]};
  }

  .bw-header .ant-menu-submenu:hover .ant-menu-title-content {
    color: ${colors["@headings-light"]} !important;
  }
  .bw-header .show-search {
    color: ${colors["@headings-light"]};
  }

  .bw-header .ant-menu-title-content .anticon-down {
    color: ${colors["@separator"]};
  }

  .bw-header .hr-line {
    border-left: 0.5px solid ${colors["@field-icon-placeholder"]};
  }

  .ant-menu-submenu-popup .ant-menu-sub li {
    background-color: ${colors["@highlight-text"]};
  }
  .mega-menu-modal .ant-modal-content,
  .mega-menu-modal .ant-modal-content ul {
    background-color: ${colors["@highlight-text"]}!important;
  };
  .bw-header.ant-menu-submenu-selected,
  .ant-menu-item-selected,{
    color: ${colors["@nav-mobile-content"]}
    background-color: ${colors["@btn-primary-hover"]}
  };
 
 .ant-menu-submenu-selected,
 .ant-menu-submenu:hover,
 .ant-menu-item-selected,
 .ant-menu-item-selected:hover {
    color: ${colors["@nav-mobile-content"]} !important;
    background-color: ${colors["@btn-primary-hover"]} !important;
  }


  .hide-arrow-element {
    color: ${colors["@separator"]};
  } 

  .searchSec , .searchSec:hover {
    background: ${colors["@nav-bg-darker"]} !important; 
  } 

`;
export default PageHeaderComponentWrapper;
