import styled from "styled-components";
import colors from "../../utils/colorsFunctional.json";
const ModalSearchComponentWrapper = styled.div`
  .modalsearch-div {
    .result-count {
      color: ${colors["@headings-dark"]};
      width: 100%;
    }

    .no-res-found {
      background: ${colors["@panel-bg"]};
      border-radius: 8px;
      padding: 12px 30px;
    }
  }
`;
export default ModalSearchComponentWrapper;
