import styled from "styled-components";
import colors from "../../utils/colorsFunctional.json";

const PagefooterWrapper = styled.div`
  .footer-items {
    color: ${colors["@text-body"]};
  }

  .copyright {
    background: {
      color: ${colors["@highlight-text"]};
    }
  }

  .footer,
  .next-footer-container,
  .copyright-content a {
    color: ${colors["@bg"]};
  }

  .copyright {
    background-color: ${colors["@highlight-text"]};
    color: ${colors["@bg"]};
  }

  .footer,
  .next-footer-container,
  .copyright-content a {
    color: ${colors["@bg"]};
  }

  .footer-title {
    color: ${colors["@headings-dark"]};
  }

  .footer,
  .next-footer-container,
  .copyright-content a {
    color: ${colors["@bg"]};
  }
  .footer-title {
    color: ${colors["@headings-dark"]};
  }
  .footer-alt-title {
    color: ${colors["@headings-dark"]};
  }

  .footer-nav {
    color: ${colors["@headings-dark"]};
  }

  .footer-nav,
  #ot-sdk-btn.ot-sdk-show-settings:hover {
    background: ${colors["@bg"]};
  }

  .footer-nav,
  #ot-sdk-btn.ot-sdk-show-settings:hover {
    color: ${colors["@text-body"]};
  }

  .footer-nav,
  .footer-alt-links,
  #ot-sdk-btn.ot-sdk-show-settings {
    color: ${colors["@headings-dark"]};
  }

  .all-lang {
    color: ${colors["@text-body"]};
  }

  .footer-alt-link {
    color: ${colors["@text-body"]};
  }

  .footer-link a {
    color: ${colors["@headings-dark"]};
  }
`;
export default PagefooterWrapper;
