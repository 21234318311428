import styled from "styled-components";
import colors from "../../utils/colorsFunctional.json";

const PaginationComponentWrapper = styled.div`
  .paginationSec .previousLink {
    border-right: 1px solid ${colors["@separator"]};
  }
  .paginationSec .nextLink {
    border-left: 1px solid ${colors["@separator"]};
  }
  .ant-pagination-item-active a {
    color: ${colors["@btn-pill-dark-text"]};
  }
  .ant-pagination-item-active a:hover {
    color: ${colors["@btn-pill-dark-text"]};
  }

  @media only screen and (min-width: 360px) and (max-width: 768.98px) {
    .paginationSec .previousLink {
      border-right: unset;
    }
    .paginationSec .nextLink {
      border-left: unset;
    }
  }
`;
export default PaginationComponentWrapper;
