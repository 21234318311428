export const language = {
  en: "English",
  es: "Spanish",
  pt: "Portuguese",
  de: "German",
  fr: "French",
  it: "Italian",
  nl: "Dutch",
  da: "Danish",
  no: "Norwegian",
  sv: "Swedish",
  fi: "Finnish",
  et: "Estonian",
  lv: "Latvian",
  lt: "Lithuanian",
  cs: "Czech",
  hu: "Hungarian",
  pl: "Polish",
  "zh-hk": "Chinese Traditional",
  "zh-cn": "Chinese Simplified",
  ja: "Japanese",
};

export const REACT_APP_TRENDING_CNT = process.env.REACT_APP_TRENDING_CNT
  ? process.env.REACT_APP_TRENDING_CNT
  : 10;
export const REACT_APP_NMM_CNT = process.env.REACT_APP_NMM_CNT
  ? process.env.REACT_APP_NMM_CNT
  : 5;
export const REACT_APP_LATEST_CNT = process.env.REACT_APP_LATEST_CNT
  ? process.env.REACT_APP_LATEST_CNT
  : 9;

export const URL_REGEX1 = /[{(,)}]/gimu; // remove paranthesis and comma
export const URL_REGEX = /[/]|\s[&]\s|\s/gimu; // replace empty space, empty space & empty space and / with -
export const CURR_TZ = "America/New_York"; // Eastern Daylight Time

export const catPageSize = 24;
export const subCatPageSize = 12;
