import styled from "styled-components";
import colors from "../../utils/colorsFunctional.json";
const SimplesearchComponentWrapper = styled.div`
  .simple-search .simple-search-input {
    background: ${colors["@field-bg"]};
  }

  .simple-search .search-title {
    color: ${colors["@field-text-label"]};
  }

  .simple-search .simple-search-btn {
    background: ${colors["@btn-primary"]};
  }

  .simple-search .simple-search-btn .search-text {
    color: ${colors["@btn-primary-text"]};
  }

  .simple-search .simple-search-btn:hover {
    background: ${colors["@btn-primary-hover"]};
  }

  .simple-search .ant-input-affix-wrapper-status-error .ant-input,
  .error-text {
    color: ${colors["@error-text"]};
  }

  .simple-search .simple-search-input .ant-input {
    color: ${colors["@field-text"]};
  }

  .simple-search .simple-search-input .ant-input::placeholder {
    color: ${colors["@field-text-placeholder"]};
  }
`;
export default SimplesearchComponentWrapper;
