/**
 * @author Pramod Turakane
 * @company Sai Systems
 * Landing Page
 *
 * This is the landing page when webapp gets loaded '/' route
 *
 */

import React, { useState, useEffect, Suspense } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Layout, ConfigProvider } from "antd";
import PageHeaderComponent from "../../components/pageheader";
import PageFooter from "../../components/pagefooter";
import { useDispatch, useSelector } from "react-redux";
import { I18Provider } from "../../i18n";
import "../../App.scss";
import "./apppage.scss";
import {
  getGlobalSettings,
  getMenuData,
  getMfooterData,
  getBnavigationData,
  getLoginBtnData,
} from "./actions";

import { routes } from "../../utils/routes";
import AppWrapper from "./app";
const { Header, Footer, Content } = Layout;

const App = () => {
  // user to invoke redux actions
  const dispatch = useDispatch();
  const [current, setCurrent] = useState("");
  //get data from redux store
  const menuData = useSelector((state) => state.appReducer.menuData);
  const mfooterData = useSelector((state) => state.appReducer.mfooterData);
  const bnavigationData = useSelector(
    (state) => state.appReducer.bnavigationData
  );

  const globalSettings = useSelector(
    (state) => state.appReducer.globalSettings
  );
  const loginBtnData = useSelector((state) => state.appReducer.loginBtnData);

  const [lang, setLang] = useState(
    localStorage.getItem("lang") ? localStorage.getItem("lang") : "EN"
  );

  const onHandleActiveLink = (e) => {
    if (!e) {
      setCurrent("");
      return false;
    }
    if (e.key) {
      setCurrent(e.key);
    } else if (e.keyPath) {
      setCurrent(e.keyPath[0]);
    }
  };

  useEffect(() => {
    //Invoke action to call API for saga
    dispatch(getMenuData());
    dispatch(getMfooterData());
    dispatch(getBnavigationData());
    dispatch(getGlobalSettings());
    dispatch(getLoginBtnData());

    const interval = setInterval(() => {
      const expIn = localStorage.getItem("exp");
      if (expIn !== null) {
        const expTime = new Date(expIn * 1000);
        const currentTime = new Date();

        if (expTime < currentTime) {
          window.confirm("Session Expired!");
          localStorage.clear();
          sessionStorage.clear();
          window.location.href = "/";
        }
      }
    }, 10000);
    return () => {
      clearInterval(interval);
      localStorage.clear();
    };
  }, [dispatch]);

  const onHandleChangeEvt = (evt) => {
    setLang(evt);
    localStorage.setItem("lang", evt);
    window.location.reload();
  };

  return (
    <AppWrapper>
      <ConfigProvider
        theme={{
          token: {
            screenXS: 0,
            screenXSMin: 0,
            screenXSMax: 360,
            screenSM: 360,
            screenSMMin: 360,
            screenSMMax: 769,
            screenMD: 769,
            screenMDMin: 769,
            screenMDMax: 1024,
            screenLG: 1024,
            screenLGMin: 1024,
            screenLGMax: 1920,
            screenXL: 1920,
            screenXLMin: 1920,
            screenXLMax: 8000,
            screenXXL: 8000,
            screenXXLMin: 8000,
          },
        }}
      >
        <div className="App app-page test" tabIndex="0">
          <Suspense fallback={<div>Loading...</div>}>
            <I18Provider locale={lang}>
              <BrowserRouter>
                <Layout>
                  <Header tabIndex="0" aria-label="Page Header section">
                    {menuData.length ? (
                      <PageHeaderComponent
                        menuData={menuData}
                        globalSettings={globalSettings}
                        handleChange={onHandleChangeEvt}
                        lang={lang}
                        handleActiveLinks={onHandleActiveLink}
                        current={current}
                        loginBtnData={loginBtnData}
                      />
                    ) : (
                      <></>
                    )}
                  </Header>
                  <Content tabIndex="0">
                    <Routes>
                      {routes.map((data, index) => {
                        return (
                          <Route
                            key={data.path}
                            path={data.path}
                            element={data.component}
                          />
                        );
                      })}
                    </Routes>
                  </Content>
                  <Footer tabIndex="0" aria-label="Page footer section">
                    <PageFooter
                      lfooterData={globalSettings}
                      bnavigationData={bnavigationData}
                      mfooterData={mfooterData}
                      handleActiveLinks={onHandleActiveLink}
                    />
                  </Footer>
                </Layout>
              </BrowserRouter>
            </I18Provider>
          </Suspense>
        </div>
      </ConfigProvider>
    </AppWrapper>
  );
};

export default App;
